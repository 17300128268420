.clients_block {
    margin: 40px 0;
}

.carousel-slide_wrapper {
    width: 250px !important;
    box-sizing: border-box;
}

.clients_item {
    padding: 30px 20px;
    width: 250px;
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
    border-radius: 30px;
    background: #fff;
    object-fit: fill;
    box-sizing: border-box;
    transition: 400ms;
}
/* .clients_item:hover {
    transform: perspective(1000px) rotateX(35deg) rotateY(4deg) rotateZ(-30deg);
    z-index: 1;
} */

.slick-next, .slick-prev {
    z-index: 1000;
}

.slick-slide {
    display: flex !important;
    justify-content: center !important;
}

.clients_item__img {
    width: 100%;
    border-radius: 20px;
    transition: 400ms;
}
/* .clients_item__img:hover {
    object-fit: fill;
    z-index: 100;
} */


.clients_item__img-full {
    width: 170px;
    /* border-radius: 20px;    */
}

.clients_item-text {
    margin-bottom: 8px;
    font-weight: 500;
    color: #343436;
}


@media (max-width: 1200px) {
    .clients_item {
        width: 220px;
        height: 150px;
        border-radius: 20px;
    }
    .carousel-slide_wrapper {
        width: 220px !important;
    }
    
}

@media (max-width: 1050px) {
    .clients_item {
        border-radius: 15px;
        overflow: hidden;
    }
}

@media (max-width: 800px) {
    .clients_block {
        margin: 30px 0;
    }

    .carousel-slide_wrapper {
        margin: 10px 0;
    }

    .clients_item {
        width: 350px;
        height: 290px;
        border-radius: 20px;
    }
    .carousel-slide_wrapper {
        width: 350px !important;
    }

    .clients_item-text {
        font-size: 18px;
    }
}

@media (max-width: 500px) {
    .clients_block {
        margin: 20px 0;
    }

    .clients_item {
        width: 250px;
        height: 190px;
        border-radius: 20px;
    }

    .carousel-slide_wrapper {
        width: 250px !important;
    }

    .clients_item-text {
        font-size: 16px;
    }

    .arrow {
        width: 15px;
    }
}