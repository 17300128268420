.App {
  font-family: "Montserrat";
}

.link {
  text-decoration: none;
}

h1, h2 {
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.marker {
  color:#0079D9;
}

.scroll-to-top {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-to-top_btn {
  position: absolute;
  right: 90px;
  width: 90px;
  cursor: pointer;
}

.notification {
  border-radius: 20px !important;
  box-shadow: none !important;
  font-family: Montserrat !important;
  font-weight: 500 !important;
  color: #222222 !important;
  background: rgba(255, 255, 255, 0.458) !important;
  border: 1px solid rgba(158, 158, 158, 0.441) !important;
  backdrop-filter: blur(10px) !important;
}

.notification-success {
  background: rgba(191, 255, 192, 0.458) !important;
}
.notification-info {
  background: rgba(191, 228, 255, 0.458) !important;
}

.btn-floating {
  position: fixed;
  bottom: 30px;
  right: 30px;
}

.animation {
  position: relative;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}
.animation:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -10px;
  right: -10px;
  background: #FFFFFF;
  height: 20px;
  width: 20px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.65s ease-out;
}

.animation:hover:before {
  transform: scale(100);
}
.animation:hover p {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}
.animation:hover {
  transition: all 0.3s ease-out;
  color: #000000;
}

.animation-white {
  position: relative;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}
.animation-white:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -15px;
  right: -15px;
  background: linear-gradient(152deg, rgba(255,255,255,1) 44%, rgba(148, 185, 255, 0.548) 61%);
  /* background: #3e6bff; */
  height: 20px;
  width: 20px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.65s ease-out;
}

.animation-white:hover:before {
  transform: scale(100);
}
.animation-white:hover p {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}
.animation-white:hover {
  transition: all 0.3s ease-out;
  color: #000;
}

@media (max-width: 500px) {
  .scroll-to-top_btn {
    right: 35px;
    width: 70px;
  }
}