.privacy_buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.privacy_buttons__btn {
    margin-right: 10px;
    padding: 20px;
    border-radius: 30px;
    color: #fff;
    font-weight: 700;
    background: #0079D9;
    cursor: pointer;
}
.privacy_paragraph {
    margin: 8px 0;
    font-size: 17px;
}