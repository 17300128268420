.form {
    margin-top: 40px;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    color: #222222;
}

.form_block-wrap {
    /* width: 700px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; */
}

.form_title {
    text-align: center;
    font-size: 24px;
    line-height: 48px;
    font-weight: 500 !important;
}

.form_subtitle {
    margin-bottom: 30px;
    text-align: center;
    font-size: 20px;
}

.form_paragraph {
    font-size: 20px;
    line-height: 32px;
}

.required {
    color: red;
}

.form_block {
    padding: 22px 20px;
    margin: 0 0 15px 0;
    width: 700px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    /* box-shadow: 0 1.2px 6px -2px rgb(0 0 0 / 60%); */
    /* box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px 0px; */
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px 0px;
    cursor: pointer;
    border-radius: none;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    transition: all 0.15s;
    box-sizing: border-box;
    outline: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-animation: fade-in 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.form_block--mb {
    padding-bottom: 40px;
}

/* .form_block--active {
    box-shadow: 0 3px 15px -3px rgb(0 0 0 / 30%);
    transform: translate3d(0, 0, 0);
    z-index: 50;
} */

.form_block--img {
    margin: 15px 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.form_block__img {
    padding: 10px;
    width: 50%;
    border-radius: 10px;
    border: 1px solid rgba(153, 153, 153, 0.392);
}

.form_block__img--category {
    margin: 0 10px;
    width: 40%;
    height: 100%;
}

.form_block__title {
    font-size: 20px;
    line-height: 32px;
    font-weight: 400 !important;
    color: #222222;
}

.form_block__input {
    margin-top: 8px;
    min-height: 38px;
    padding-left: 10px;
    background-color: transparent;
    border-radius: 0;
    -webkit-appearance: none;
    display: block;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
    line-height: inherit;
    width: 98%;
    /* border: 0; */
    background: #d0d0d03d;
    border: 1px solid #acacac92;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    border-radius: 8px;
    /* border-bottom: 1.5px solid #7EACFF; */
    outline: 0;
}

.form_block-radio {
    display: flex;
    justify-content: space-between;
}
/* HIDE RADIO */
.form_block-radio [type=radio] { 
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* IMAGE STYLES */
  .form_block-radio [type=radio] + img {
    padding: 3px;
    width: 55px;
    cursor: pointer;
  }
  
  /* CHECKED STYLES */
  .form_block-radio [type=radio]:checked + img {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -khtml-border-radius: 50%;
    border-radius: 100px;
    outline: 2px solid #0079D9;
  }

.form_button-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form_button-block__next--no-data {
    padding: 8px 15px;
    font-size: 13px;
    line-height: 20px;
    text-transform: uppercase;
    color: #fff;
    font-family: inherit;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    background-color: #999;
    z-index: 2;
    position: relative;
    border: 0;
    border-radius: 7px;
    -webkit-font-smoothing: subpixel-antialiased;
    box-shadow: 0 2px 6px -2px rgb(0 0 0 / 50%);
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    transition: all 0.1s;
    outline: 0;
    -webkit-user-select: none;
    user-select: none;
}

.form_button-block__next {
    padding: 8px 15px;
    font-size: 13px;
    line-height: 20px;
    text-transform: uppercase;
    color: #000;
    font-family: inherit;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    background-color: #fff;
    z-index: 2;
    position: relative;
    border: 0;
    border-radius: 7px;
    -webkit-font-smoothing: subpixel-antialiased;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px 0px;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    transition: all 0.1s;
    outline: 0;
    -webkit-user-select: none;
    user-select: none;
    transition: .1s ease-in-out;
}

.form_button-block__next--send {
    color: #fff;
    background: rgb(0, 169, 0);
    box-shadow: rgba(51, 255, 119, 0.429) 0px 8px 24px 0px;
}

.form_button-block__clear {
    cursor: pointer;
}

.form_block__radio-group {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
}

.form_block__radio-group--category {
    line-height: 24px;
    flex-direction: row;
    justify-content: space-around;
}

.form_block__radio-group-item {
    margin: 8px 5px 8px 0;
}

.form_block__download {
    margin: 15px 0;
    padding: 10px 15px;

    border-radius: 100px;
    border: 1px solid #0079D9;
    font-weight: 500;
    color: #0079D9;
}

.image-uploader {
    display: flex !important;
    flex-direction: column;
    align-items: center;
}

.image-uploader input[type="file" i]::-webkit-file-upload-button {
    position: relative;
    left: 30%;
    padding: 10px 15px !important;
    color: #fff;
    border: 0;
    border-radius: 100px;
    background: #7EACFF !important;
    font-size: 14px;
    font-weight: 500 !important;
}

.image-uploader input[type="file"] {
    color: rgba(0, 0, 0, 0) !important;
}

.image-uploader_title {
    margin-bottom: 14px;
    font-size: 14px;
}

.image-uploader_btn {
    padding: 10px 15px !important;
    color: #fff;
    border: 0;
    border-radius: 100px;
    background: #7EACFF !important;
    font-size: 14px;
    font-weight: 500 !important;
}

.image-uploader_img-wrapper {
    position: relative;
    margin-top: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 30%;
    border-radius: 14px;
    border: 1px solid rgba(153, 153, 153, 0.392);
}

.image-uploader_img {
    padding: 8px;
    width: 700px;
    max-width: 100%;
    border-radius: 14px;
    position: relative;
    text-align: left;
    cursor: pointer;
    border-radius: none;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    transition: all 0.15s;
    box-sizing: border-box;
    outline: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-font-smoothing: subpixel-antialiased;
}

.image-uploader_btn-rmv {
    position: absolute;
    top: -9px;
    right: -9px;
    color: #fff;
    background: #7EACFF;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    font-size: 19px;
    font-weight: 400;
    line-height: 30px;
    width: 30px;
    height: 30px;
}

.fileUploader {
    display: flex !important;

}
.fileContainer {
    padding: 0;
    box-shadow: none;
}
.uploadPictureContainer {
    box-shadow: none !important;
    background: inherit !important;
    border-radius: 24px;
}

@media (max-width: 800px) {
    .form_block {
        width: auto;
    }
    .form_block-radio {
        justify-content: start;
        flex-wrap: wrap;
    }
    .form_block__img {
        width: 90%;
    }
    .form_block__img--category {
        width: 47%;
        margin: 0 2px;
    }
}

@media (max-width: 500px) {
    .form_block-radio [type=radio] + img {
        padding: 4px;
        width: 45px;
    }
    .image-uploader input[type="file" i]::-webkit-file-upload-button {
        /* left: 35%; */
    }
    .form_block__input {
        width: 96%;
        font-size: 14px;
    }
    .form_block__title {
        font-size: 16px;
    }
    .form_paragraph {
        font-size: 16px;
        line-height: 24px;
    }
    .form_title {
        font-size: 24px;
        line-height: 33px;
        font-weight: 700;
    }
    .form_subtitle {
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 16px;
    }
    .form_button-block__clear {
        font-size: 13px;
    }
}

@media (max-width: 320px) {
    .form_button-block__clear {
        font-size: 12px;
    }
    .form_title {
        font-size: 20px;
    }
    .form_block__title {
        font-size: 14px;
    }
    .form_paragraph {
        font-size: 14px;
    }
}



@-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
