.connect {
    margin: 60px 0;
}

.connect_block__flex {
    margin-top: 40px;
    display: flex;
    justify-content: center;
}
.connect_block {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.connect_block__item {
    margin-bottom: 20px;
    padding: 10px;
    display: flex;
    align-items: center;
    width: 652px;
    height: 113px;

    background: #FFF;
    border-radius: 100px;
    border: 2px solid #7EACFF;

    box-sizing: border-box;
}

.connect_block__item-badge {
    margin-right: 35px;
    padding: 35px 40px;

    color: #FFF;
    font-size: 20px;
    font-weight: 700;

    border-radius: 100px;
    background: #7EACFF;
}

.connect_block__item-input {
    width: 70%;
    height: 100%;

    font-size: 25px;
    color: #343436;

    border: none;
    border-radius: 100px;
    outline: none;
    font-family: Montserrat;
}

.captcha {
  display: flex;
  /* align-items: center;
  justify-content: center; */
  width: 100%;
  margin-bottom: 15px;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.connect_block__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 652px;
    height: 113px;
    border-radius: 40px;

    color: #FFF;
    font-size: 20px;
    font-weight: 700;
    background: #7EACFF;
    cursor: pointer;
    transition: 0.3s ease-in-out
}

.connect_block__btn:hover {
    background: #0079D9;
}

@media (max-width: 1050px) {
    .connect_block__item {
        margin-bottom: 15px;
        padding: 10px;
        width: 552px;
        height: 95px;
    }   
    
    .connect_block__item-badge {
        margin-right: 25px;
        padding: 27px 30px;
    }

    .connect_block__item-input {
        font-size: 20px;
    }

    .connect_block__btn {
        width: 552px;
        height: 95px;
        border-radius: 30px;
    }
}

@media (max-width: 600px) {
    .connect {
        margin: 30px 0;
    }

    .connect_block__flex {
        margin-top: 30px;
    }

    .connect_block__item {
        padding: 0 10px;
        width: 380px;
        height: 85px;
    }   
    
    .connect_block__item-badge {
        margin-right: 20px;
        padding: 20px 25px;
        font-size: 16px;
    }

    .connect_block__item-input {
        height: 80%;
        font-size: 16px;
    }

    .connect_block__btn {
        width: 100%;
        height: 85px;
        font-size: 16px;
    }
}

@media (max-width: 410px) {
    .connect_block__flex {
        margin-top: 20px;
    }

    .connect_block__item {
        padding: 0 7px;
        width: 100%;
        height: 60px;
    }   
    
    .connect_block__item-badge {
        margin-right: 10px;
        padding: 14px 17px;
        font-size: 14px;
    }

    .connect_block__item-input {
        font-size: 14px;
    }

    .connect_block__btn {
        height: 60px;
        font-size: 14px;
    }
}