.delivery-payment {
    margin-right: 20px;
    padding: 30px;
    display: flex;
    background: #fff;
    border-radius: 50px;
}

.delivery-payment_block__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 30px;

}

.delivery-payment_items__item-img {
    width: 50px;
    margin-right: 10px;
}

.delivery-payment_block--margin {
    margin-right: 15px;
}

.delivery-payment_items__item {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    font-size: 15px;
}

.delivery-payment_items__item--last {
    margin-bottom: 0px;
}

.delivery-payment_phones__img {
  width: 500px;
}

.delivery-payment_phones__img--animation {
	-webkit-animation: slide-topic 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-topic 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.delivery-payment_items--animation {
	-webkit-animation: slide-topic 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-topic 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
  
@-webkit-keyframes slide-topic {
  0% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
            -webkit-filter: blur(7px);
            filter: blur(7px);
            opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
            -webkit-filter: blur(0px);
            filter: blur(0px);
            opacity: 1;
  }
}
@keyframes slide-topic {
  0% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
            -webkit-filter: blur(7px);
            filter: blur(7px);
            opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
            -webkit-filter: blur(0px);
            filter: blur(0px);
            opacity: 1;
  }
}

@media (max-width: 1200px) {
  .delivery-payment_phones__img {
    width: 400px;
  }
}

@media (max-width: 1050px) {
    .delivery-payment {
        margin-right: 0px;
    }
}

@media (max-width: 600px) {
  .delivery-payment_phones__img {
    width: 310px;
  }
}

@media (max-width: 425px) {
    .delivery-payment {
        width: 100%;
        flex-direction: column;
    }

    .delivery-payment_block--top-margin {
        margin-top: 30px;
    }
}

@media (max-width: 360px) {
  .delivery-payment_phones__img {
    width: 220px;
  }
}

@media (max-width: 320px) {
    .delivery-payment {
        width: 85%;
    }
}