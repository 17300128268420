.wrap {
    position: fixed;
    top: -10px;
    width: 100%;
    height: 101vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.183);
  }
  
  .modal {
    min-width: 320px;
    width: 30%;
    background-color: #fff;
    padding: 2em 3em;
    text-align: center;
    border-radius: .5em;
    box-sizing: border-box;
    -webkit-animation: scale-up-center 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-center 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  .is-active {
    display: block;
  }
  
  .modal-image {
    width: 70px;
    height: 70px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 0 0 2px #48DB71;
    padding: 11px 10px 2px;
    margin-bottom: 2em;
    box-sizing: border-box;
  }

  .modal-image_check {
    width: 40px;
    -webkit-animation: rotate-center 0.6s ease-in-out both;
    animation: rotate-center 0.6s ease-in-out both;
  }
  
  .h1 {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: .5em;
  }
  
  .p {
    margin-bottom: 2em;
    color: #666;
  }
  
  .btn {
    font-size: 1.25em;
    font-weight: bold;
    background-color: #000;
    border: none;
    padding: .5em 1em;
    color: #fff;
    box-shadow: 0 0 0 2px #000 inset;
    border-radius: .25em;
    cursor: pointer;
    transition: background .4s ease, color .4s ease;
  }
  
  .btn:hover {
    box-shadow: 0 0 0 2px #000 inset;
    color: #000;
    background-color: transparent;
  }
  
  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  

  @-webkit-keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  