.order-info {
    padding: 50px 0;
    border-radius: 0 0 60px 60px;
    background-color: #E8E6FA;
}

.order-info_block {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.order-info_block__img {
    width: 550px;
}

.order-info_block__img--animation {
	-webkit-animation: slide-topic 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-topic 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.order-info_block-numbers--animation {
    -webkit-animation: slide-topic .8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: slide-topic .8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-topic {
    0% {
      -webkit-transform: translateY(100px);
              transform: translateY(100px);
              -webkit-filter: blur(7px);
              filter: blur(7px);
              opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
              -webkit-filter: blur(0px);
              filter: blur(0px);
              opacity: 1;
    }
  }
  @keyframes slide-topic {
    0% {
      -webkit-transform: translateY(100px);
              transform: translateY(100px);
              -webkit-filter: blur(7px);
              filter: blur(7px);
              opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
              -webkit-filter: blur(0px);
              filter: blur(0px);
              opacity: 1;
    }
  }

.order-info_block-numbers {
    width: 600px;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
}

.order-info_block-numbers__item {
    display: flex;
    align-items: center;
    height: 120px;
}

.number_gradient--right {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 120px;
    height: 120px;
    border-radius: 100px;
    background: linear-gradient(89.51deg, #0079D9 -0.8%, rgba(0, 121, 217, 0) 50%);
}

.number_gradient--left {
    margin-right: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 180px;
    height: 120px;
    border-radius: 100px;
    background: linear-gradient(270.51deg, #0079D9 0.43%, rgba(0, 121, 217, 0) 50%);
}

.circle_number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;

    color: #fff;
    font-size: 35px;
    font-weight: 500;
    border-radius: 100px;
    background: #0079D9;
}

.order_info__text-block {
    font-weight: 500;
}

.order_info__text-block--left {
    margin-right: 20px;
    text-align: right;
}

@media (max-width: 1200px) {
    .order-info_block-numbers {
        row-gap: 0px;
    }

    .order-info_block__img {
        width: 400px;
    }

    .number_gradient--right {
        width: 80px;
        height: 80px;
    }

    .number_gradient--left {
        width: 110px;
        height: 80px;
    }

    .circle_number {
        width: 60px;
        height: 60px;
        font-size: 20px;
    }

}

@media (max-width: 1050px) {
    .order-info_block {
        flex-direction: column;
    }

    .number_gradient--right {
        width: 90px;
        height: 90px;
    }

    .number_gradient--left {
        width: 120px;
        height: 90px;
    }

    .circle_number {
        width: 70px;
        height: 70px;
        font-size: 22px;
    }
}

@media (max-width: 800px) {
    .order-info {
        padding: 30px 0;
    }

    .number_gradient--left {
        margin-right: 0px;
    }

    .order_info__text-block {
        font-size: 18px;
    }
}

@media (max-width: 620px) {
    .order-info_block-numbers {
        width: 100%;
    }

    .number_gradient--right {
        width: 70px;
        height: 70px;
    }

    .number_gradient--left {
        width: 110px;
        height: 70px;
    }

    .circle_number {
        width: 50px;
        height: 50px;
        font-size: 16px;
    }

    .order_info__text-block {
        font-size: 16px;
    }
}

@media (max-width: 500px) {
    .order-info_block__img {
        width: 320px;
    }

    .order-info_block-numbers__item {
        height: 100px;
    }

    .number_gradient--right {
        width: 100px;
    }

    .number_gradient--left {
        width: 160px;
    }

    .circle_number {
        width: 50px;
        height: 50px;
        font-size: 18px;
    }

    .order_info__text-block {
        font-size: 13px;
    }
}