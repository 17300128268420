.save-bussines {
    margin: 60px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.save-bussiness__cards {
    margin-top: 40px;
    display: flex;
    column-gap: 15px;
    row-gap: 15px;

    justify-content: center;
    flex-wrap: wrap;
}

/* .save-bussiness__cards--item {
    padding: 24px 35px;
    display: flex;
    align-items: center;
    width: 31%;
    height: 120px;
    border-radius: 24px;
    background-color: #fff;
    box-sizing: border-box;
    transition: .3s linear;
} */

.save-bussiness__cards-img {
    margin-right: 20px;
    width: 70px;
}

.save-business__undertext {
    margin-top: 10px;
    display: flex;
    align-items: center;

    font-size: 14px;
}

.paralax {
    perspective: 1000px;
    position: relative;
    width: 31%;
    height: 120px;
}
.paralax--animation {
	-webkit-animation: slide-zoom-top 1.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-zoom-top 1.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes slide-zoom-top {
    0% {
      -webkit-transform: translateY(100px);
              transform: translateY(100px);
              -webkit-filter: blur(7px);
              filter: blur(7px);
              -webkit-transform: scale(0.5);
              transform: scale(0.5);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
              opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
              -webkit-filter: blur(0px);
              filter: blur(0px);
              -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
              opacity: 1;
    }
  }
  @keyframes slide-zoom-top {
    0% {
      -webkit-transform: translateY(100px);
              transform: translateY(100px);
              -webkit-filter: blur(7px);
              filter: blur(7px);
              -webkit-transform: scale(0.5);
              transform: scale(0.5);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
              opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
              -webkit-filter: blur(0px);
              filter: blur(0px);
              -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
              opacity: 1;
    }
  }
  
  
.paralax a {
    opacity: 0;
    position: absolute;
    z-index: 1;
    width: 50%;
    height: 50%;
}
.paralax a:hover, .paralax a:focus {
    transform: translateZ(50px);
}
.paralax a:hover ~ .paralax__card, .paralax a:focus ~ .paralax__card {
    color: #0079D9;
}
.paralax a:hover ~ .paralax__card .paralax__border, .paralax a:focus ~ .paralax__card .paralax__border {
    transform: translateZ(24px);
}
.paralax a:active ~ .paralax__card .paralax__border {
    transform: none;
}
.paralax a:nth-child(1) {
    top: 0;
    left: 0;
}
.paralax a:nth-child(1):hover ~ .paralax__card, .paralax a:nth-child(1):focus ~ .paralax__card {
    transform-origin: right bottom;
    transform: rotateX(5deg) rotateY(-5deg) translateZ(0);
}
.paralax a:nth-child(2) {
    top: 0;
    right: 0;
}
.paralax a:nth-child(2):hover ~ .paralax__card, .paralax a:nth-child(2):focus ~ .paralax__card {
    transform-origin: left bottom;
    transform: rotateX(5deg) rotateY(5deg) translateZ(0);
}
.paralax a:nth-child(3) {
    bottom: 0;
    right: 0;
}
.paralax a:nth-child(3):hover ~ .paralax__card, .paralax a:nth-child(3):focus ~ .paralax__card {
    transform-origin: left top;
    transform: rotateX(-5deg) rotateY(5deg) translateZ(0);
}
.paralax a:nth-child(4) {
    bottom: 0;
    left: 0;
}
.paralax a:nth-child(4):hover ~ .paralax__card, .paralax a:nth-child(4):focus ~ .paralax__card {
    transform-origin: right top;
    transform: rotateX(-5deg) rotateY(-5deg) translateZ(0);
}
.paralax .paralax__card {
    transition: all 300ms ease-out;
    position: relative;
    z-index: 0;
}
.paralax .paralax__card .paralax__border {
    transition: all 300ms ease-out;
    transform-origin: center center;
    position: absolute;
    top: 12px;
    left: 12px;
}
.paralax .paralax__card {
    height: 100%;
    padding: 24px 35px;
    display: flex;
    align-items: center;
    border-radius: 24px;
    background-color: #fff;
    color: black;
    box-sizing: border-box;
    transition: .3s linear;
}
.paralax .paralax__card h1 {
    font-size: 24px;
}

@media (max-width: 1050px) {
    .save-bussiness__cards {
        column-gap: 15px;
        row-gap: 15px;
    }
    /* .save-bussiness__cards--item, .paralax {
        padding: 15px 10px !important;
        width: 31% !important;
    } */

    .save-bussiness__cards-img {
        margin-right: 10px;
        width: 45px;
    }
}

@media (max-width: 800px) {
    .save-bussines {
        margin: 30px 0;
    }

    .save-bussiness__cards {
        margin-top: 30px;
        row-gap: 15px;
        flex-wrap: wrap;
    }

    .save-bussiness__cards--item, .paralax {
        width: 100% !important;
        border-radius: 20px !important;

        font-size: 18px;
    }

    .save-bussiness__cards-img {
        margin-right: 30px;
        width: 65px;
    }
}

@media (max-width: 345px) {
    .save-bussiness__cards {
        row-gap: 0px;
    }

    .save-bussiness__cards--item, .paralax {
        height: 110px !important;
        padding: 5px 20px !important;
        font-size: 16px !important;
    }

    .save-bussiness__cards-img {
        margin-right: 25px;
        width: 45px;
    }
}