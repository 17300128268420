.control-shop {
    margin: 60px 0;
}

.control-shop_block {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.control-shop_block {
    display: flex;
}

.control-shop_cards {
    width: 54%;
    display: grid;
    gap: 10px;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 50% 50%;
    box-sizing: border-box;
}

.control-shop_two-cards {
    margin-right: 20px;
}

.control-shop_two-cards__item-check {
    margin-bottom: 10px;
    width: 40px;
}

.control-shop_two-cards__item {
    padding: 20px 20px 15px 20px;
    border-radius: 40px;

    font-size: 16px;
    background: #fff;
    -webkit-animation: shadow-pop-bl-out 0.3s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
            animation: shadow-pop-bl-out 0.3s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}

.control-shop_two-cards__item:hover {
    -webkit-animation: shadow-pop-bl 0.3s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
            animation: shadow-pop-bl 0.3s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}

.control-shop_two-cards__item--animation {
    -webkit-animation: slide-topic 1.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-topic 1.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    /* -webkit-animation: scale-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: scale-in-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
}

@-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  

.control-shop_two-cards__item--margin {
    margin-bottom: 35px;
}

.control-shop_two-cards__item-title {
    margin-bottom: 10px;
    font-weight: 700;
}

.control-shop_one-card__elements-item {
    display: flex;
    align-items: center;
}

.dot {
    margin-right: 10px;
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background: linear-gradient(60.61deg, #0079D9 10.35%, rgba(0, 121, 217, 0.5) 89.21%);
}

.control-shop_one-card__elements {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin-top: 20px;
}

.control-shop_one-card {
    width: 250px;
    padding: 20px 24px;
    border-radius: 40px;

    font-size: 18px;
    background: #fff;
}

.control-shop_phones__img {
    width: 500px;
}

.control-shop_phones__img--animation {
	-webkit-animation: slide-topic 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-topic 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* .delivery-payment_items--animation {
	-webkit-animation: slide-topic 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-topic 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
} */

@media (max-width: 1200px) {    
    .control-shop_two-cards__item {
        font-size: 14px;
    }


    .control-shop_phones__img {
        width: 400px;
    }
}

@media (max-width: 1050px) {
    .control-shop_cards {
        width: 100% !important;
        justify-content: space-between;
        grid-template-rows: 33% 33% 33%;
        grid-template-columns: 50% 50%;
    }

    .control-shop_two-cards__item {
        padding-bottom: 20px;
    }

    .control-shop_block {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 600px) {
    .control-shop {
        margin: 30px 0;
    }

    .control-shop_phones__img {
        width: 310px;
    }

    .control-shop_two-cards__item {
        border-radius: 24px;
    }
}

@media (max-width: 500px) {
    .control-shop_two-cards__item-check {
        margin-bottom: 5px;
        width: 32px;
    }
    .control-shop_two-cards__item-title {
        margin-bottom: 10px;
    }
    .control-shop_two-cards__item {
        padding: 15px;
        font-size: 13px;
    }
}

@media (max-width: 360px) {
    .control-shop_cards {
        width: 90% !important;
    }

    .control-shop_two-cards__item {
        padding: 11px;
        font-size: 12px;
    }

    .control-shop_phones__img {
        width: 220px;
    }
}

@-webkit-keyframes shadow-pop-bl {
    from {
        -webkit-box-shadow: 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE;
                box-shadow: 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE;
        -webkit-transform: translateX(0) translateY(0);
                transform: translateX(0) translateY(0);
      }
    to {
      -webkit-box-shadow: -1px 1px #197DBE, -2px 2px #197DBE, -3px 3px #197DBE, -4px 4px #197DBE, -5px 5px #197DBE, -6px 6px #197DBE, -7px 7px #197DBE, -8px 8px #197DBE;
              box-shadow: -1px 1px #197DBE, -2px 2px #197DBE, -3px 3px #197DBE, -4px 4px #197DBE, -5px 5px #197DBE, -6px 6px #197DBE, -7px 7px #197DBE, -8px 8px #197DBE;
      -webkit-transform: translateX(8px) translateY(-8px);
              transform: translateX(8px) translateY(-8px);
    }
  }
  @keyframes shadow-pop-bl {
    from {
        -webkit-box-shadow: 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE;
                box-shadow: 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE;
        -webkit-transform: translateX(0) translateY(0);
                transform: translateX(0) translateY(0);
      }
    to {
      -webkit-box-shadow: -1px 1px #197DBE, -2px 2px #197DBE, -3px 3px #197DBE, -4px 4px #197DBE, -5px 5px #197DBE, -6px 6px #197DBE, -7px 7px #197DBE, -8px 8px #197DBE;
              box-shadow: -1px 1px #197DBE, -2px 2px #197DBE, -3px 3px #197DBE, -4px 4px #197DBE, -5px 5px #197DBE, -6px 6px #197DBE, -7px 7px #197DBE, -8px 8px #197DBE;
      -webkit-transform: translateX(8px) translateY(-8px);
              transform: translateX(8px) translateY(-8px);
    }
  }

  @-webkit-keyframes shadow-pop-bl-out {
    0% {
        -webkit-box-shadow: -1px 1px #197DBE, -2px 2px #197DBE, -3px 3px #197DBE, -4px 4px #197DBE, -5px 5px #197DBE, -6px 6px #197DBE, -7px 7px #197DBE, -8px 8px #197DBE;
                box-shadow: -1px 1px #197DBE, -2px 2px #197DBE, -3px 3px #197DBE, -4px 4px #197DBE, -5px 5px #197DBE, -6px 6px #197DBE, -7px 7px #197DBE, -8px 8px #197DBE;
        -webkit-transform: translateX(8px) translateY(-8px);
                transform: translateX(8px) translateY(-8px);
      }
    100% {
      -webkit-box-shadow: 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE;
              box-shadow: 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE;
      -webkit-transform: translateX(0) translateY(0);
              transform: translateX(0) translateY(0);
    }
  }
  @keyframes shadow-pop-bl-out {
    0% {
        -webkit-box-shadow: -1px 1px #197DBE, -2px 2px #197DBE, -3px 3px #197DBE, -4px 4px #197DBE, -5px 5px #197DBE, -6px 6px #197DBE, -7px 7px #197DBE, -8px 8px #197DBE;
                box-shadow: -1px 1px #197DBE, -2px 2px #197DBE, -3px 3px #197DBE, -4px 4px #197DBE, -5px 5px #197DBE, -6px 6px #197DBE, -7px 7px #197DBE, -8px 8px #197DBE;
        -webkit-transform: translateX(8px) translateY(-8px);
                transform: translateX(8px) translateY(-8px);
      }
    100% {
      -webkit-box-shadow: 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE;
              box-shadow: 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE, 0 0 #197DBE;
      -webkit-transform: translateX(0) translateY(0);
              transform: translateX(0) translateY(0);
    }
  }