.title {
    font-size: 45px;
    font-weight: 500;
    text-align: center;
}

.subtitle {
    margin-top: 20px;
    font-size: 25px;
    font-weight: 500;
    text-align: center;
}

@media (max-width: 1050px) {
    .title {
        font-size: 35px;
    }
    
    .subtitle {
        font-size: 20px;
    }
}

@media (max-width: 800px) {
    .title {
        font-size: 30px;
    }
    
    .subtitle {
        margin-top: 15px;
        font-size: 15px;
    }
}

@media (max-width: 500px) {
    .title {
        font-size: 26px;
    }
}

@media (max-width: 345px) {
    .title {
        font-size: 23px;
    }
}