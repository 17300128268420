.footer {
    margin: 60px 0;
}

.footer_logo {
    width: 150px;
}

.footer_links {
    margin-top: 40px;
}

.footer_link__item {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}

.footer_link__item-text {
    margin-left: 20px;
    font-size: 26px;
    font-weight: 500;
    color: #343436;
    transition: all .5s ease 0s;
}

.footer_link__item-text:hover {
    color: #0079D9;
}

@media (max-width: 1050px) {
    .footer_link__item-text {
        font-size: 20px;
    }

    .footer_link__item-hashtag {
        width: 30px;
    }
}

@media (max-width: 360px) {
    .footer_link__item-text {
        font-size: 16px;
    }

    .footer_link__item-hashtag {
        width: 26px;
    }
    .footer_logo {
        width: auto;
    }
}