.container {
    max-width: 1170px;
    margin: 0 auto;
}

@media (max-width: 1200px) {
    .container {
        max-width: 1025px;
    }
}

@media (max-width: 1050px) {
    .container {
        max-width: 764px;
    }
}

@media (max-width: 800px) {
    .container {
        max-width: 481px;
    }
}

@media (max-width: 500px) {
    .container {
        max-width: 320px;
    }
}

@media (max-width: 345px) {
    .container {
        max-width: 290px;
    } 
}