.home_video {
    padding: 0px 0 30px 0;
    border-radius: 60px;
    background: #E8E6FA;
    overflow: hidden;
}

.home_video-blocks {
    display: flex;
    justify-content: space-between;
}

.home_video__text-block {
    display: flex;
    flex-direction: column;
}

.text-block_text {
    margin-top: 30px;
    max-width: 600px;
    font-size: 50px;
    font-weight: bold;
    line-height: 70px;
    letter-spacing: 0.03em;
}

.text-block_text--animation {
	-webkit-animation: slide-top 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(100px);
              transform: translateY(100px);
              -webkit-filter: blur(7px);
              filter: blur(7px);
              opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
              -webkit-filter: blur(0px);
              filter: blur(0px);
              opacity: 1;
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(100px);
              transform: translateY(100px);
              -webkit-filter: blur(7px);
              filter: blur(7px);
              opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
              -webkit-filter: blur(0px);
              filter: blur(0px);
              opacity: 1;
    }
  }
  

.text-block_sub-text {
    width: 75%;
    margin-top: 40px;
    line-height: 175%;
    font-size: 22px;
    font-weight: 600;
}

.text-block_sub-text--animation {
	-webkit-animation: slide-top 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
  

.text-block_text--badge {
    padding: 5px 15px;
    border-radius: 100px;

    font-size: 46px;
    color: #fff;
    background-color: #7EACFF;
    cursor: pointer;
}

.text-block_telegram {
    position:relative;
    height: 100%;
}

.text-block_telegram__btn-wrapper {
    position: absolute;
    bottom: 0;
}

.text-block_telegram__eye {
    width: 60px;
}

.text-block_telegram__next {
    margin-left: 10px;
    width: 20px;
}

.mobile-btns {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-block_telegram__play--create-shop {
    width: 70% !important;
    background: linear-gradient(270deg, #5e23ff, #203eff, #00f2f2) !important; 
    background-size: 300% 300% !important;
    animation: example 15s ease infinite !important;
    -webkit-animation: example 15s ease infinite !important;
    -moz-animation: example 15s ease infinite !important;
    margin-left: 10px;
    font-weight: 600;
}

.play_icon {
    width: 20px;
}

.home_video__video-block {
    margin-top: 20px;
    position: relative;
    top: 0;
    left: 0;
    overflow: hidden;
}
.mockup {
    position: relative;
    top: 0;
    left: 0;
    height: 650px;
    z-index: 2;
    box-sizing: border-box;
}

.gif {
    position: absolute;
    top: 45px;
    left: 0px;
    width: 100% !important;
    height: 565px !important;
    z-index: 1;
    box-sizing: border-box;
}

.preview-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%, -50%);
    height: 565px !important;
    z-index: 1;
    box-sizing: border-box;  
}

.text-block_telegram__qr {
    margin-bottom: 35px;
    display: flex;
    align-items: center;
}

.text-block_telegram__img {
    margin-right: 40px;
    border-radius: 26px;
    width: 190px;
}

.text-block_telegram__phone {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-block_telegram__text {
    margin-top: 15px;
    width: 100px;

    line-height: 26px;
    text-align: center;
    font-size: 20px;
}

.home-video_links {
    width: 70%;
    display: flex;
    flex-direction: column;
}

.text-block_telegram__btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 25px 0 15px 0;
    padding: 18px 20px;
    border-radius: 100px;
    background: #7EACFF;

    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    transition: 0.3s ease-in-out;
    
}

.text-block_telegram__btn:hover {
    transform: scale(1.030)
    /* box-shadow: 0px 0px 15px #0079D9; */
}

.home_video__video-block_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-block_telegram__play {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 100px;

    background: #7EACFF;
    color: #FFF;
    transition: 0.3s ease-in-out;
    box-sizing: border-box;
}

.text-block_telegram__btn-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.text-block_telegram__btn--create {
    margin-left: 10px;
    background: linear-gradient(270deg, #5e23ff, #203eff, #00f2f2); 
    background-size: 300% 300%;
    animation: example 15s ease infinite;
    -webkit-animation: example 15s ease infinite;
    -moz-animation: example 15s ease infinite;
}
  

@media (max-width: 1050px) {
    .text-block_telegram__img {
        width: 150px;
    }
    .text-block_text {
        max-width: 400px;
        font-size: 35px;
        line-height: 55px;
    }

    .text-block_text--badge {
        padding: 3px 10px;
        font-size: 30px;
    }

    .text-block_telegram__eye {
        width: 50px;
    }

    .text-block_telegram__text {
        font-size: 18px;
    }

    .text-block_sub-text {
        margin-top: 30px;
        font-size: 20px;
    }

    .gif {
        height: 500px;
    }

    .text-block_telegram__btn {
        padding: 10px 15px;
        font-size: 12px;
    }
}

@media (max-width: 800px) {
    .home_video {
        padding: 30px 0;
    }

    .text-block_text {
        margin-top: 0px;
        max-width: 500px;

        line-height: 45px;
        text-align: center;
        font-size: 30px;
    }

    .text-block_sub-text {
        width: 100%;
        margin-top: 20px;
        font-size: 16px;
        text-align: center;
    }

    .text-block_text--badge {
        font-size: 25px;
    }
    
    .home_video-blocks {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .text-block_telegram {
        display: none;
    }

    .mobile-btns {
        margin-top: 10px;
    }
}

@media (max-width: 500px) {
    .home_video {
        padding: 20px 0;
    }

    .text-block_text {
        font-size: 26px;
    }

    .text-block_telegram__btn {
        font-size: 12px;
    }

    .text-block_text--badge {
        font-size: 24px;
    }
}

@media (max-width: 345px) {
    .text-block_text {
        font-size: 24px;
        line-height: 40px;
    }

    .text-block_text--badge {
        font-size: 22px;
    }
}
@media (max-width: 320px) {
    .text-block_telegram__btn {
        font-size: 11px;
    }
}

@-webkit-keyframes tilt-in-fwd-tr {
    0% {
      -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
              transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
              transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
      opacity: 1;
    }
  }
  @keyframes tilt-in-fwd-tr {
    0% {
      -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
              transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
              transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
      opacity: 1;
    }
  }

  @keyframes example {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }
  @-webkit-keyframes example {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }
  @-moz-keyframes example {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }