.cost-tariff {
    margin: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cost-tariff__main {
    display: flex;
    justify-content: space-between;
    margin: 40px 0 20px 0;
    padding: 40px 50px;
    width: 100%; 
    background: radial-gradient( circle farthest-corner at 10% 20%,  rgb(0, 0, 161) 0.3%, rgba(28,181,224,1) 90.2% );
    background-size: 130% 130%;
    animation: examplea 8s ease infinite;
    -webkit-animation: examplea 8s ease infinite;
    -moz-animation: examplea 8s ease infinite;
    border-radius: 50px;
    box-sizing: border-box;
}

.cost-tariff__main-item {
    display: flex;
    flex-direction: column;
}

.cost-tariff__main-item-title {
    margin-bottom: 10px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
}

.cost-tariff__main-item-sale {
    margin-bottom: -15px;
    display: flex;
    align-items: center;
}

.cost-tariff__main-item-sale-prevcost {
    margin-right: 25px;
    font-size: 40px;
    font-weight: 600;
    color: #F1EFFF;
    text-decoration-line: line-through;
    text-transform: uppercase;
}

.cost-tariff__main-item-sale-saletext {
    font-size: 23px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
}

.cost-tariff__main-item-sale-saletext--underline {
    text-decoration: underline;
}

.cost-tariff__main-item-price {
    display: flex;
    align-items: center;
}

.cost-tariff__main-item-price-sum {
    margin-right: 20px;
    font-size: 85px;
    font-weight: 700;
    color: #000;
    background: linear-gradient(151deg, rgba(126,172,255,1) 47%, rgba(255,255,255,1) 50%, rgba(126,172,255,1) 53%);
    /* background: linear-gradient(135deg, rgba(126,172,255,1) 47%, rgba(255,255,255,1) 50%, rgba(126,172,255,1) 53%); */
    /* background: linear-gradient(#65ffb2,#7EACFF,#b97ede); */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-attachment: fixed;
}

.cost-tariff__main-item-price-badge {
    padding: 12px 20px;
    font-size: 20px;
    font-weight: 700;
    border-radius: 200px;
    text-transform: uppercase;
}

.cost-tariff__main-item-date {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    background: radial-gradient(50% 6622.59% at 50% 50%, #FFFFFF 34.9%, rgba(255, 255, 255, 0) 100%);
    transition: .5s ease-in-out;
}

.cost-tariff__main-item-date:hover {
    background: #FFFFFF;
    border-radius: 100px;
    transform: scale(1.01);
}

.cost-tariff__main-item-listname {
    margin-bottom: 22px;
    font-size: 20px;
    font-weight: 700;
}

.cost-tariff__main-item-list-item {
    margin-bottom: 22px;
    display: flex;
    align-items: center;
}

.cost-tariff__main-item-list-item-icon {
    margin-right: 15px;
}

.cost-tariff__main-item-list-item-title {
    font-size: 20px;
    font-weight: 500;
}

.cost-tariff__main-item-block {
    padding: 20px 30px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 50px;
}

.cost-tariff__btn {
    width: 250px;
    padding: 18px 20px;
    background: #7EACFF;
    border-radius: 100px;
    text-align: center;

    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    transition: 0.3s ease-in-out;
}


@media (max-width: 1050px) {
    .cost-tariff__main {
        flex-direction: column;
        margin: 30px 0 15px 0;
        padding: 30px 0px 0px 0px;
        border-radius: 40px;
    }

    .cost-tariff__main-item--m {
        padding: 0 40px;
        margin-bottom: 30px;
    }

    .cost-tariff__main-item-block {
        border-radius: 40px;
    }
}

@media (max-width: 768px) {
    .cost-tariff {
        margin: 40px 0;
    }

    .cost-tariff__main {
        margin-top: 20px;
    }

    .cost-tariff__main-item-title {
        font-size: 18px;
    }

    .cost-tariff__main-item-sale {
        margin-bottom: -10px;
    }

    .cost-tariff__main-item-sale-prevcost {
        font-size: 26px;
    }
    
    .cost-tariff__main-item-sale-saletext {
        font-size: 20px;
    }

    .cost-tariff__main-item-price-sum {
        margin-right: 15px;
        font-size: 70px;
    }
    
    .cost-tariff__main-item-price-badge {
        padding: 10px 18px;
        font-size: 18px;
    }

    .cost-tariff__main-item-date {
        margin-bottom: 15px;
        height: 30px;
        font-size: 20px;
    }

    .cost-tariff__btn {
        width: 94%;
    }
}

@media (max-width: 500px) {
    .cost-tariff__main {
        padding-top: 20px;
        margin-top: 15px;
        border-radius: 25px;
    }

    .cost-tariff__main-item--m {
        padding: 0 25px;
        margin-bottom: 20px;
    }

    .cost-tariff__main-item-block {
        border-radius: 25px;
    }

    .cost-tariff__main-item-title {
        font-size: 14px;
    }

    .cost-tariff__main-item-sale {
        margin-bottom: -5px;
    }

    .cost-tariff__main-item-sale-prevcost {
        font-size: 20px;
    }
    
    .cost-tariff__main-item-sale-saletext {
        font-size: 16px;
    }

    .cost-tariff__main-item-price-sum {
        margin-right: 10px;
        font-size: 48px;
    }
    
    .cost-tariff__main-item-price-badge {
        padding: 5px 12px;
        font-size: 13px;
    }

    .cost-tariff__main-item-date {
        margin-top: 5px;
        margin-bottom: 10px;
        height: 24px;
        font-size: 16px;
    }

    .cost-tariff__main-item-listname {
        margin-top: 10px;
        margin-bottom: 12px;
        font-size: 18px;
    }

    .cost-tariff__main-item-list-item {
        margin-bottom: 15px;
    }

    .cost-tariff__main-item-list-item-icon {
        width: 23px;
        height: 23px;
    }

    .cost-tariff__main-item-list-item-title {
        font-size: 16px;
    }

    .cost-tariff__btn {
        width: 90%;
    }
}

@media (max-width: 320px) {
    .cost-tariff__main-item-sale-prevcost {
        font-size: 18px;
    }
    
    .cost-tariff__main-item-sale-saletext {
        font-size: 14px;
    }

    .cost-tariff__main-item-price-sum {
        font-size: 43px;
    }

    .cost-tariff__main-item-price-badge {
        padding: 3px 8px;
        font-size: 12px;
    }
}

@keyframes examplea {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }
  @-webkit-keyframes examplea {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }
  @-moz-keyframes examplea {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }