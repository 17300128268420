.header {
    position: sticky;
    top: 0;
    background: #f1efff;
    /* backdrop-filter: blur(410px); */
    /* background-color: #fff; */
    /* border-radius: 0 0 30px 30px; */
    padding: 5px 0;
    z-index: 1001;
}

.header_wrap-logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header_wrapper-links {
    display: flex;
    align-items: center;
    font-weight: 500;
}

.header_links__wrapper {
    display: flex;
}

.header_wrapper-links__item-call {
    padding: 10px 17px;
    margin: 0 8px;
    display: flex;
    align-items: center;

    border-radius: 100px;
    color: #fff;
    background-color: #7EACFF;
    cursor: pointer;
}

.header_wrapper-links__item {
    padding: 10px 17px;
    margin: 0 8px;

    color: #343436;

    border-radius: 100px;
    cursor: pointer;
    transition: all .5s ease 0s;
}
.header_wrapper-links__item:hover {
    background-color: #E8E6FA;
}

@media (max-width: 1050px) {
    .header_wrapper-links__item-call {
        margin: 0 0px;    
    }

    .header_wrapper-links__item {
        margin: 0 0px;
    }

    .header_wrapper-links {
        font-size: 14px;
    }
}

@media (max-width: 800px) {
    .header_logo {
        width: 100px;
    }

    .burger_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 100px;
        /* background-color: #E8E6FA; */
        cursor: pointer;
    }

    .header_wrapper-links__item-call {
        margin: 0 15px;
    }
}

@media (max-width: 500px) {
    .header_logo {
        width: 90px;
    }

    .header_wrapper-links__item-call {  
        margin: 0 10px;
        padding: 5px 10px;
        font-size: 14px;
    }
}

@media (max-width: 345px) {
    .header_wrapper-links__item-call {  
        margin: 0 7px;
        padding: 2px 10px;
    }
    .burger_btn {
        width: 40px;
        height: 40px;
    }

    .burger_icon {
        width: 25px;
    }
}