.fast-sale_block {
    display: flex;
    margin-top: 40px;
    padding: 30px 0 0 0;

    border-radius: 60px 60px 0 0;
    background: #7EACFF;
    overflow: hidden;
}

.fast-sale_block__badges {
    display: flex;
    flex-direction: column;
}

.fast-sale_block-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fast-sale_block-flex__img {
    width: 600px;
}

.fast-sale_block-flex__img--animation {
	-webkit-animation: slide-topc 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-topc 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-topc {
    0% {
      -webkit-transform: translateY(300px);
              transform: translateY(300px);
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  @keyframes slide-topc {
    0% {
      -webkit-transform: translateY(300px);
              transform: translateY(300px);
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  

.fast-sale_block__badges {
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    width: 300px;
    margin-left: 60px;
}

.fast-sale_block__badges--animation {
	-webkit-animation: slide-botc 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-botc 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-botc {
    0% {
      -webkit-transform: translateY(-300px);
              transform: translateY(-300px);
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  @keyframes slide-botc {
    0% {
      -webkit-transform: translateY(-300px);
              transform: translateY(-300px);
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

.fast-sale_block__badges-item {
    width: 230px;
    transition: 400ms;
}
.fast-sale_block__badges-item:hover {
    transform: skew(.312rad);
    /* transform: perspective(1000px) rotateX(35deg) rotateY(4deg) rotateZ(-30deg); */
    z-index: 1;
}

@media (max-width: 1050px) {
    .fast-sale_block__badges {
        margin-left: 0;
        margin-bottom: 40px;
        width: 100%;

        column-gap: 20px;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .fast-sale_block__badges-item {
        width: 270px;
    }

    .fast-sale_block-flex {
        flex-direction: column-reverse;
    }

    .fast-sale_block-flex__img {
        width: 550px;
    }
}

@media (max-width: 800px) {
    .fast-sale_block {
        margin-top: 30px;
        padding: 30px 0 0 0;
    }

    .fast-sale_block__badges-item {
        width: 230px;
    }

    .fast-sale_block-flex__img {
        width: 450px;
    }
}

@media (max-width: 500px) {
    .fast-sale_block__badges-item {
        width: 150px;
    }

    .fast-sale_block-flex__img {
        width: 400px;
    }
}

@media (max-width: 410px) {
    .fast-sale_block-flex__img {
        width: 320px;
    }
}

@media (max-width: 360px) {
    .fast-sale_block__badges-item {
        width: 120px;
    }
}