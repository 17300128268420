.colors-shop {
    margin: 60px 0;
}

.colors-shop__block {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.colors-shop__entrance {
    width: 100%;
}

.colors-shop_img-wrapper {
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.colors-shop__block-opportunities {
    width: 90%;
}

.colors-shop__block-opportunities-title {
    margin-bottom: 20px;
    font-size: 26px;
    font-weight: 700;
}

.colors-shop__block-opportunities-items {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 15px;
}

.colors-shop__block-opportunities-items-item {
    padding: 10px 15px;
    display: flex;
    align-items: center;

    border-radius: 100px;
    background-color: #fff;
    cursor: pointer;
    transition: 400ms;
}

.colors-shop__block-opportunities-items-item:hover {
    transform: scale(1.04);
    transition: 400ms;
}

.colors-shop__block-opportunities-items-item-img {
    margin-right: 10px;
}

.colors-shop__block-opportunities-definition {
    padding: 20px 25px;
    border-radius: 24px;
    background: #7EACFF;

    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    color: #FFF;
}

.colors-shop__block-opportunities-definition-underline {
    font-weight: 700;
    text-decoration: underline;
}

.two-phones {
    width: 100%;
}

.scroller {
    margin-top: 30px;
    padding: 30px 0 20px 0;
    border-radius: 60px;
    background: #E8E6FA;
}

.slick-slider{
    margin: 20px 0px;
}
.slick-dots {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.colors-dots {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.colors-dot {
    width: 40px !important;
    height: 40px !important;
    border-radius: 100px;
}

.slick-dots {
    bottom: auto;
    top: -60px;
}

.slick-dots li {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 60px !important;
    height: 20px;
}

.carousel-slide_wrapper {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    outline: none;
}

.colors-shop__block-opportunities--animation {
	-webkit-animation: slide-topic 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-topic 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.carousel-slide_wrapper--animation {
    -webkit-animation: flip-in-ver-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: flip-in-ver-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes flip-in-ver-right {
    0% {
      -webkit-transform: rotateY(-80deg);
              transform: rotateY(-80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
      opacity: 1;
    }
  }
  @keyframes flip-in-ver-right {
    0% {
      -webkit-transform: rotateY(-80deg);
              transform: rotateY(-80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
      opacity: 1;
    }
  }
  
  
@-webkit-keyframes slide-topic {
  0% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
            -webkit-filter: blur(7px);
            filter: blur(7px);
            opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
            -webkit-filter: blur(0px);
            filter: blur(0px);
            opacity: 1;
  }
}
@keyframes slide-topic {
  0% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
            -webkit-filter: blur(7px);
            filter: blur(7px);
            opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
            -webkit-filter: blur(0px);
            filter: blur(0px);
            opacity: 1;
  }
}

.carousel-slide {
    height: 600px;
}

.colors-dot--1 {
    background: #84B818;
}
.colors-dot--2 {
    background: #007AFF;
}
.colors-dot--3 {
    background: #FF7500;
}
.colors-dot--4 {
    background: #FFA6A6;
}
.colors-dot--5 {
    background: #7400FF;
}

.qrcode {
    margin-top: 15px;
    width: 130px;
}

.slick-prev {
    left: -5px;
}

.slick-next {
    right: -5px;
}

.colors-shop_img-wrapper--animation {
	-webkit-animation: slide-topic 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-topic 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
  
  
@media (max-width: 1200px) {
    .two-phones {
        width: 800px;
    }

    .carousel-slide {
        height: 550px;
    }
}

@media (max-width: 1050px) {
    .two-phones {
        width: 450px;
    }

    .carousel-slide {
        margin-top: 25px;
        height: 450px;
    }

    .colors-shop__block-opportunities-items {
        column-gap: 10px;
        row-gap: 10px;
    }

    .scroller {
        margin-top: 20px;
        padding-top: 10px;
    }
    
    .slick-dots {
        top: -35px;
    }

    .colors-dots {
        width: 35%;
    }

    .text-block_telegram__btn--m {
        text-align: center;
        width: 75%;
    }
}

@media (max-width: 800px) {
    .colors-shop {
        margin: 30px 0;
    }

    .colors-shop__block {
        margin-top: 30px;
        flex-direction: column;
    }

    .colors-shop__block-opportunities {
        width: 100%;
        text-align: center;
    }

    .colors-shop__block-opportunities-items {
        justify-content: center;
    }

    .carousel-slide {
        height: 550px;
    }

    .scroller {
        padding-top: 45px;
    }

    .colors-dots {
        width: 48%;
    }

    .text-block_telegram__btn--m {
        width: auto;
        padding: 15px !important;
    }
}


@media (max-width: 600px) {
    .two-phones {
        width: 410px;
    }
    .colors-dots {
        width: 65%;
    }
}

@media (max-width: 500px) {
    .colors-shop__block-opportunities-title {
        font-size: 23px;
        margin-bottom: 15px;
    }

    .colors-shop__block-opportunities-items-item {
        padding: 8px 12px;
        width: 100%;
        text-align: left;
    }

    .colors-shop__block-opportunities-items-item-img {
        margin-right: 15px;
    }
    .carousel-slide {
        height: 500px;
    }
}

@media (max-width: 410px) {
    .two-phones {
        width: 320px;
    }
}

@media (max-width: 345px) {
    .carousel-slide {
        height: 450px;
    }
    .colors-shop__block-opportunities-items-item-img {
        width: 20px;
        height: 20px;
    }

    .colors-shop__block-opportunities-definition {
        padding: 15px 20px;
        font-size: 16px;
        border-radius: 20px;
    }
}