.faq {
    margin: 60px 0;
}

.faq__items {
    margin-top: 40px;
}

.faq__items-item {
    padding: 35px 45px;
    margin-bottom: 20px;
    background: #ffffff;
    box-shadow: 0px 8px 24px rgba(150, 158, 166, 0.2);
    border-radius: 35px;
    box-sizing: border-box;
}


summary::-webkit-details-marker {
    display: none;
}
summary::-moz-list-bullet {
    list-style-type: none;
}
summary::marker {
    display: none;
}
summary {
    width: 95%;
    display: inline-block;
    padding: 0.3em 1.5em 0.3em 0;
    font-size: 1.2em;
    cursor: pointer;
    position: relative;
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
    -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently */
}

summary:before {
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    color: transparent;
    background: url("../../Assets/FAQ/plus.svg") no-repeat 50% 50% / 40px 40px;
    width: 40px;
    height: 40px;
    content: "";
    transition: transform 0.5s;
}

details[open] > summary:before {
    background: url("../../Assets/FAQ/minus.svg") no-repeat 50% 50% / 40px 40px;
}

details[open] summary ~ * {
    animation: toggle 0.6s ease-in-out;
}

details[open] > summary {
    color: #007AFF;
}

@keyframes toggle {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
} 

summary:focus {
    outline: 0;
}

details {
    display: block;
    margin-bottom: 0.5rem;
    transition: 400ms;
}

/* details[open] {

}
details[open] ~ details {

} */

.faq__items-item-title-list-item {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.6;
}

.faq__items-item-title-list-item--link {
    color: #007AFF;
}

.faq__items-item-row {
    margin: 10px 0;
    display: flex;
}

.faq__items-item-row-gallery {
    position: relative;
    width: 162px !important;
    height: 162px !important;
}

.faq__items-item-row-gallery-link-icon {
    position: absolute;
    right: 5px;
    bottom: 5px;
}

.faq__items-item-row-gallery-link-img {
    width: 162px;
    height: 162px;
    object-fit: cover;
    border-radius: 10px;
}

.faq__items-item-title-list-item--side {
    padding-left: 10px;
    border-left: 3px solid #007AFF;
}

/* .go-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background-color: #00838d;
    border-radius: 0 4px 0 32px;
}
.go-arrow {
    margin-top: -4px;
    margin-right: -4px;
    color: white;
    font-family: courier, sans;
} */


@media (max-width: 768px) {
    .faq__items-item {
        padding: 20px 30px;
        border-radius: 25px;
        transition: 400ms;
    }

    summary {
        padding: 0.4em 2.1em 0.4em 0;
        font-size: 1em;
        line-height: 1.5;
        transition: 400ms;
    }

    summary:before {
        /* margin-right: -0.2em; */
        background: url("../../Assets/FAQ/plus.svg") no-repeat 50% 50% / 35px 35px;
        width: 35px;
        height: 35px;
        transition: 400ms;
    }

    details[open] > summary:before {
        transition: 400ms;
        background: url("../../Assets/FAQ/minus.svg") no-repeat 50% 50% / 35px 35px;
    }

    .faq__items-item-title-list {
        margin-block-start: 0px !important;
        margin-block-end: 0em !important;
        padding-inline-start: 30px !important;
    }

    .faq__items-item-title-list-item {
        font-size: 16px;
    }

    .faq__items-item-row-gallery {
        width: 125px !important;
    }

    .faq__items-item-row-gallery-link-img {
        width: 125px;
    }
}

@media (max-width: 500px) {
    .faq__items {
        margin-top: 25px;
    }

    .faq__items-item {
        padding: 17px 27px;
        margin-bottom: 15px;
        border-radius: 22px;
    }

    .faq__items-item-title--m {
        margin-right: 15px;
    }

    summary {
        padding: 0.3em 1.5em 0.3em 0;
        font-size: 0.8em;
    }

    summary:before {
        background: url("../../Assets/FAQ/plus.svg") no-repeat 50% 50% / 30px 30px;
        width: 30px;
        height: 30px;
    }

    details[open] > summary:before {
        background: url("../../Assets/FAQ/minus.svg") no-repeat 50% 50% / 30px 30px;
    }

    .faq__items-item-title-list {
        padding-inline-start: 20px !important;
    }

    .faq__items-item-title-list-item {
        font-size: 14px;
    }

    .faq__items-item-row-gallery-link-icon {
        width: 30px;
        height: 30px;
    }

    .faq__items-item-row-gallery {
        width: 90px !important;
    }

    .faq__items-item-row-gallery-link-img {
        width: 90px;
        /* height: 90px; */
        border-radius: 7px;
    }
}

@media (max-width: 320px) {
    summary {
        padding: 0.2em 1.5em 0.3em 0;
        font-size: 0.8em;
    }

    summary:before {
        background: url("../../Assets/FAQ/plus.svg") no-repeat 50% 50% / 30px 30px;
        width: 30px;
        height: 30px;
    }

    details[open] > summary:before {
        background: url("../../Assets/FAQ/minus.svg") no-repeat 50% 50% / 30px 30px;
    }

    .faq__items-item-title-list {
        padding-inline-start: 17px !important;
    }

    .faq__items-item-title-list-item {
        font-size: 13px;
    }
}