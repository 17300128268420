.bmenu {
    position: absolute;
    padding: 30px 25px;
    margin-left: auto;
    margin-right: auto;
    top: 60px;
    left: 0;
    right: 0;
    width: 70%;
    height: 65vh;
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    border-radius: 40px;
    text-align: center;
    background: #FFF;
    box-shadow: 0px 3px 5px 0px #00000040;
    /* -webkit-animation: tilt-in-fwd-tr 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: tilt-in-fwd-tr 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
    -webkit-animation: scale-up-tr 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation: scale-up-tr 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    z-index: 1000;
}

.bmenu_item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    /* border: 3px solid #7EACFF; */
    font-weight: 500;
    background: #F1EFFF;
    border-radius: 60px;
}

.bmenu_item__link {
    color: #343436;
}

@-webkit-keyframes scale-up-tr {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}
@keyframes scale-up-tr {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}


/* @-webkit-keyframes tilt-in-right-1 {
    0% {
      -webkit-transform: rotateX(-30deg) translateX(300px) skewX(30deg);
              transform: rotateX(-30deg) translateX(300px) skewX(30deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
              transform: rotateX(0deg) translateX(0) skewX(0deg);
      opacity: 1;
    }
  }
  @keyframes tilt-in-right-1 {
    0% {
      -webkit-transform: rotateX(-30deg) translateX(300px) skewX(30deg);
              transform: rotateX(-30deg) translateX(300px) skewX(30deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
              transform: rotateX(0deg) translateX(0) skewX(0deg);
      opacity: 1;
    }
  } */
  