.customization {
    margin-top: 60px;
}

.customization_block {
    margin-top: 40px;
    padding: 50px 0;

    border-radius: 60px;
    background: #7EACFF;
}

.customization_block-items {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
}

.customization_block-items--animation {
	-webkit-animation: fade-in-bottom 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-bottom 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(100px);
              transform: translateY(100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(100px);
              transform: translateY(100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  
  

.customization_block-items__item {
    padding: 20px 30px 20px 20px;
    width: 48%;
    height: 150px;
    display: flex;
    align-items: center;

    border-radius: 100px;
    background: #F1EFFF;
    font-weight: 500;
    box-sizing: border-box;
    transition: 0.3s ease-in-out
}

.customization_block-items__item:hover {
    background: #0079D9;
    color: #fff;
    transform: scale(1.05);
}

.customization_block-items__item-circle {
    padding: 35px;
    margin-right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    background: #E8E6FA;
    border-radius: 50%;
    box-sizing: border-box;
}

.customization_block-items__item-circle--icon {
    width: 50px;
}

@media (max-width: 800px) {
    .customization {
        margin-top: 30px;
    }

    .customization_block {
        margin-top: 30px;
        padding: 30px 0;
    }

    .customization_block-items__item {
        padding: 20px 30px 20px 20px;
        width: 100%;
    }
}

@media (max-width: 500px) {
    .customization {
        margin-top: 20px;
    }

    .customization_block {
        margin-top: 20px;
        padding: 20px 0;
    }

    .customization_block-items__item {
        padding: 10px 20px 10px 15px;
        width: 100%;
        height: 130px;
        font-size: 14px;
    }

    .customization_block-items__item-circle {
        padding: 25px;
        margin-right: 18px;
    }

    .customization_block-items__item-circle--icon {
        width: 50px;
    }
}