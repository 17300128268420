html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Montserrat';
  font-display: optional;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F1EFFF;
  color: #343436;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: local('Montserrat'),
       url('./Font/Montserrat/static/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: local('Montserrat'),
       url('./Font/Montserrat/static/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: local('Montserrat'),
       url('./Font/Montserrat/static/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: local('Montserrat'),
       url('./Font/Montserrat/static/Montserrat-Bold.ttf') format('truetype');
}